import React from 'react';
import { useEffect, useState } from 'react';

// import {
//     DataGrid,
//     GridColDef,
//     GridRowsProp,
//     GridToolbarContainer,
//     GridToolbarExport,
//     GridRowParams,
//     GridColumnGroupingModel,
// } from "@mui/x-data-grid";
// import { DataGrid, GridColDef, GridColumnGroupingModel } from '@mui/x-data-grid';

import {
    Box,
    FormControlLabel,
    RadioGroup,
    Radio,
    Typography,
    TextField,
    Select,
    MenuItem,
    SelectChangeEvent,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";

const buttonStyle = {
    minWidth: 0,
    padding: "2px",
    margin: '2px 4px',
    border: '1px solid blue'
}

const detailButtonStyle = {
    minWidth: 0,
    padding: "2px",
    margin: '2px 4px',
    border: '1px solid green'
}

const cellStyle = {
    verticalAlign: 'middle',
    padding: '20px',
    border: '1px solid #000',
    color: '#000',
    fontSize: '14px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
}
const headCrossStyle = {
    position: 'sticky',
    top: '0',
    left: '0',
    background: 'none',
    borderTop: 'none',
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    zIndex: 1,
    '&::before': {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        borderTop: '1px solid #000',
        borderBottom: '1px solid #000',
        borderLeft: '1px solid #000',
        borderRight: '1px solid #000',
        background: '#ccc',
        zIndex: -1,
    }    
}
const headColStyle = {
    position: 'sticky',
    top: '0',
    left: '0',
    background: 'none',
    borderTop: 'none',
    borderBottom: 'none',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        borderTop: '1px solid #000',
        borderBottom: '1px solid #000',
        background: '#ccc',
        zIndex: -1,
    }    
}

const rowCrossStyle = {
    position: 'sticky',
    top: '0',
    left: '0',
    background: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        borderLeft: '1px solid #000',
        borderRight: '1px solid #000',
        background: '#ccc',
        zIndex: -1
    }
}

export interface resultProps {
    props:{
    }
}

const TrafficConfirmationScreen:React.FC<resultProps> = ({props}) => {
    // View details
    // Display time period
    const [sortWidth, setSortWidth] = useState(0)
    const [displayTimePeriod, setDisplayTimePeriod] = useState(0)
    const [viewDetails, setViewDetails] = useState(0)
    const [tilt, setTilt] = useState<boolean>(false)

    const [openSort, setOpenSort] = useState<boolean>(false)
    const [openDisplayTime, setOpenDisplayTime] = useState<boolean>(false)
    const [openDetailItems, setOpenDetailItems] = useState<boolean>(false)
    // ソート
    const [sortValue, setSortValue] = useState<string>('0')

    const [carItem, setCarItem] = useState<boolean>(true)
    const [inPersonItem, setInPersonItem] = useState<boolean>(true)
    const [outPersonItem, setOutPersonItem] = useState<boolean>(true)
    
    // const [visibleColumns, setVisibleColumns] = useState(
    //     columns.map((col) => col.field)
    // );

    useEffect(() => {
        windowTile()
    }, [])

    // 端末の傾きを検知する
    window.addEventListener("orientationchange", () => {
        windowTile()
    });

    const windowTile = () => {
        var direction = Math.abs(window.orientation);
        console.log('direction ' + direction)
        let width = window.innerWidth
        if (window.innerWidth > window.innerHeight) {
            width = window.innerHeight
        }
        if (direction === 90) {
            // 横向きの処理
            setTilt(true)
            setSortWidth(Math.floor(width * 0.5))
            setDisplayTimePeriod(Math.floor(width))
            setViewDetails(Math.floor(width))
        } else {
        // 縦向きの処理
            setTilt(false)
            setSortWidth(Math.floor(width * 0.8))
            setDisplayTimePeriod(Math.floor(width))
            setViewDetails(Math.floor(width))
        }
    }

    // const columns: GridColDef[] = [
    //     { field: 'SurveyDate', headerName: '調査日', width: 90 },
    //     { field: 'SurveyTimne', headerName: '調査時間', width: 90 },
    //     {
    //         field: 'carTotal',
    //         headerName: '車総台数',
    //         width: 80,
    //     },
    //     {
    //         field: 'frontTotal',
    //         headerName: '総台数',
    //         width: 80,
    //     },
    //     {
    //         field: 'car_front_light',
    //         headerName: '（内）軽',
    //         width: 80,
    //     },
    //     {
    //         field: 'car_front_large',
    //         headerName: '（内）大型',
    //         width: 80,
    //     },
    // ];
      
    const Datarows = [
        { id: 1, count_date: '2025-03-09', count_time: '09:00～10:30', carTotal: '10', front_traffic_jam_time: '0', frontTotal: '0', car_front_light: '33', car_front_large: '77',  car_side_a_total: '1', car_side_a_light: '1', car_side_a_large: '2', car_side_b_total: '2', car_side_b_light: '3', car_side_b_large: '0', car_back_total: '0', car_back_light: '0', car_back_large: '0' },
        { id: 2, count_date: '2025-03-10', count_time: '09:00～10:30', carTotal: '20', front_traffic_jam_time: '0', frontTotal: '0', car_front_light: '33', car_front_large: '77',  car_side_a_total: '1', car_side_a_light: '1', car_side_a_large: '2', car_side_b_total: '2', car_side_b_light: '3', car_side_b_large: '0', car_back_total: '0', car_back_light: '0', car_back_large: '0' },
        { id: 3, count_date: '2025-03-10', count_time: '09:00～10:30', carTotal: '30', front_traffic_jam_time: '0', frontTotal: '0', car_front_light: '33', car_front_large: '77',  car_side_a_total: '1', car_side_a_light: '1', car_side_a_large: '2', car_side_b_total: '2', car_side_b_light: '3', car_side_b_large: '0', car_back_total: '0', car_back_light: '0', car_back_large: '0' },
        { id: 4, count_date: '2025-03-10', count_time: '09:00～10:30', carTotal: '40', front_traffic_jam_time: '0', frontTotal: '0', car_front_light: '33', car_front_large: '77',  car_side_a_total: '1', car_side_a_light: '1', car_side_a_large: '2', car_side_b_total: '2', car_side_b_light: '3', car_side_b_large: '0', car_back_total: '0', car_back_light: '0', car_back_large: '0' },
        { id: 5, count_date: '2025-03-10', count_time: '09:00～10:30', carTotal: '50', front_traffic_jam_time: '0', frontTotal: '0', car_front_light: '33', car_front_large: '77',  car_side_a_total: '1', car_side_a_light: '1', car_side_a_large: '2', car_side_b_total: '2', car_side_b_light: '3', car_side_b_large: '0', car_back_total: '0', car_back_light: '0', car_back_large: '0' },
        { id: 6, count_date: '2025-03-10', count_time: '09:00～10:30', carTotal: '60', front_traffic_jam_time: '0', frontTotal: '0', car_front_light: '33', car_front_large: '77',  car_side_a_total: '1', car_side_a_light: '1', car_side_a_large: '2', car_side_b_total: '2', car_side_b_light: '3', car_side_b_large: '0', car_back_total: '0', car_back_light: '0', car_back_large: '0' },
        { id: 7, count_date: '2025-03-10', count_time: '09:00～10:30', carTotal: '70', front_traffic_jam_time: '0', frontTotal: '0', car_front_light: '33', car_front_large: '77',  car_side_a_total: '1', car_side_a_light: '1', car_side_a_large: '2', car_side_b_total: '2', car_side_b_light: '3', car_side_b_large: '0', car_back_total: '0', car_back_light: '0', car_back_large: '0' },
        { id: 8, count_date: '2025-03-10', count_time: '09:00～10:30', carTotal: '80', front_traffic_jam_time: '0', frontTotal: '0', car_front_light: '33', car_front_large: '77',  car_side_a_total: '1', car_side_a_light: '1', car_side_a_large: '2', car_side_b_total: '2', car_side_b_light: '3', car_side_b_large: '0', car_back_total: '0', car_back_light: '0', car_back_large: '0' },
        { id: 9, count_date: '2025-03-10', count_time: '09:00～10:30', carTotal: '90', front_traffic_jam_time: '0', frontTotal: '0', car_front_light: '33', car_front_large: '77',  car_side_a_total: '1', car_side_a_light: '1', car_side_a_large: '2', car_side_b_total: '2', car_side_b_light: '3', car_side_b_large: '0', car_back_total: '0', car_back_light: '0', car_back_large: '0' },
    ];
      
    // const columnGroupingModel: GridColumnGroupingModel = [
    //     {
    //         groupId: 'Survey',
    //         headerName: "",
    //         freeReordering: true,
    //         children: [{field: 'SurveyDate'}, {field: 'SurveyTimne'}]
    //     },
    //     {
    //         groupId: 'headerCarFront',
    //         headerName: "前面",
    //         freeReordering: true,
    //         children: [{ field: 'carTotal' }, { field: 'frontTotal' }, { field: 'car_front_light'}, {field: 'car_front_large'}],
    //     },
    //     {
    //         groupId: 'headerCarSideA',
    //         headerName: "側道A",
    //         freeReordering: true,
    //         children: [{ field: 'sideATotal' }],
    //     },
    //     {
    //         groupId: 'headerCarSideB',
    //         headerName: "側道B",
    //         freeReordering: true,
    //         children: [{ field: 'sideBTotal' }],
    //     },
    //     {
    //         groupId: 'headerCarBack',
    //         headerName: "裏道",
    //         freeReordering: true,
    //         children: [{ field: 'backTotal' }],
    //     },
    // ];

    // ヘッダーイベント
    const handleSortHeader = () => {
        setOpenSort(!openSort)
    }
    const handleDisplayTime = () => {
        setOpenDisplayTime(!openDisplayTime)
    }
    const handleDetailItems = () => {
        setOpenDetailItems(!openDetailItems)
    }
    // ソートイベント

    // 詳細表示イベント
    const handleDetail = (target: string) => {
        if (target === 'car') {
            setCarItem(!carItem)
        } else if (target === 'in_person') {
            setInPersonItem(!inPersonItem)
        } else if (target === 'out_person') {
            setOutPersonItem(!outPersonItem)
        }
    }


    const handleRadioButtonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSortValue(e.target.value)
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{
                margin: '4px 4px 10px 4px'
            }}>
                <Box sx={{
                    display: 'flex',
                    flexWrap: tilt ? 'nowrap' : 'wrap',
                    overflowX: tilt ? 'auto' : 'hidden',
                    '& .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root': {
                        margin: 0
                    },
                    '& .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded': {
                        margin: 0
                    }
                }}>
                    <Button
                        variant="outlined"
                        size="small"
                        sx={{margin: '0 4px 0 0', width: '30px', maxWidth: '30px', minWidth: '30px', padding: '10px 0',
                        height: '30px', maxHeight: '30px', minHeight: '30px',
                        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
                        }}
                        // onClick={(e) => {onClickHandle('back')}}
                    >≪</Button>
                    <Box sx={{
                        width: sortWidth,
                    }}>
                        <Typography
                            onClick={(e) => {handleSortHeader()}}
                        >ソート</Typography>
                        <Box sx={{
                            display: openSort ? 'block' : 'none'
                        }}>
                            <RadioGroup
                                row
                                value={sortValue}
                                onChange={(e) =>handleRadioButtonChange(e)}
                            >
                                <FormControlLabel
                                    value={"0"}
                                    control={
                                        <Radio
                                            size="small"
                                        />
                                    }
                                    label={"調査日時"}
                                />
                                <FormControlLabel
                                    value={"1"}
                                    control={
                                        <Radio
                                            size="small"
                                        />
                                    }
                                    label={"車総台数"}
                                />
                                <FormControlLabel
                                    value={"2"}
                                    control={
                                        <Radio
                                            size="small"
                                        />
                                    }
                                    label={"人総数"}
                                />
                            </RadioGroup>
                        </Box>
                    </Box>
                    <Box sx={{
                        width: displayTimePeriod,
                    }}>
                        <Typography
                            onClick={(e) => {handleDisplayTime()}}
                        >表示時間帯</Typography>
                        <Box sx={{
                            display: openDisplayTime ? 'flex' : 'none'
                        }}>
                            <Button size="small" sx={buttonStyle}>朝</Button>
                            <Button size="small" sx={buttonStyle}>昼</Button>
                            <Button size="small" sx={buttonStyle}>夜</Button>
                            <Button size="small" sx={buttonStyle}>休日</Button>
                            <Button size="small" sx={buttonStyle}>朝特定</Button>
                            <Button size="small" sx={buttonStyle}>昼特定</Button>
                            <Button size="small" sx={buttonStyle}>夜特定</Button>
                        </Box>
                    </Box>
                    <Box sx={{
                        width: viewDetails,
                    }}>
                        <Typography
                            onClick={(e) => {handleDetailItems()}}
                        >詳細表示</Typography>
                        <Box sx={{
                            display: openDetailItems ? 'flex' : 'none'
                        }}>
                            <Button size="small" onClick={(e) => {handleDetail('car')}} sx={detailButtonStyle}>車</Button>
                            <Button size="small" onClick={(e) => {handleDetail('in_person')}} sx={detailButtonStyle}>人5m内</Button>
                            <Button size="small" onClick={(e) => {handleDetail('out_person')}} sx={detailButtonStyle}>人5m外</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                marginTop: '10px',
                width:  '100%',
            }}>
                {/* <DataGrid
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    columnGroupingModel={columnGroupingModel}
                    experimentalFeatures={{ columnGrouping: true }}
                    disableColumnMenu={true}
                /> */}
                <TableContainer sx={{
                    maxWidth: '700px',
                    height: '300px',
                    overflowX: 'auto',
                    overflowY: 'auto',
                    '&-webkit-overflow-scrolling': 'touch'
                }}>
                    <Table sx={{
                        borderCollapse: 'collapse',
                        borderSpacing: '0',
                        width: '100%',
                    }}>
                        <TableHead sx={{
                            backgroundColor: 'aliceblue'
                        }}>
                            <TableRow>
                                <TableCell sx={headCrossStyle}>
                                        <Box>調査日</Box>
                                        <Box>調査時間</Box>
                                </TableCell>
                                {
                                    carItem &&
                                    <>
                                        <TableCell sx={headColStyle}>
                                            <>前面</>
                                            <Box sx={{ display: 'flex'}}>
                                                <Box>渋滞閉鎖<br />（分）</Box>
                                                <Box>総台数</Box>
                                                <Box>（内）<br />軽</Box>
                                                <Box>（内）<br />大型</Box>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={headColStyle}>
                                            <>側道A</>
                                            <Box sx={{ display: 'flex'}}>
                                                <Box>渋滞閉鎖<br />（分）</Box>
                                                <Box>総台数</Box>
                                                <Box>（内）<br />軽</Box>
                                                <Box>（内）<br />大型</Box>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={headColStyle}>
                                            <>側道B</>
                                            <Box sx={{ display: 'flex'}}>
                                                <Box>総台数</Box>
                                                <Box>（内）<br />軽</Box>
                                                <Box>（内）<br />大型</Box>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={headColStyle}>
                                            <>裏道</>
                                            <Box sx={{ display: 'flex'}}>
                                                <Box>総台数</Box>
                                                <Box>（内）<br />軽</Box>
                                                <Box>（内）<br />大型</Box>
                                            </Box>
                                        </TableCell>
                                    </>
                                }
                                {
                                    inPersonItem &&
                                    <>
                                        <TableCell sx={headColStyle}>
                                            <>中高生</>
                                        </TableCell>
                                        <TableCell sx={headColStyle}>
                                            <>65歳以上</>
                                            <Box sx={{ display: 'flex'}}>
                                                <Box>男</Box>
                                                <Box>女</Box>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={headColStyle}>
                                            <>その他</>
                                            <Box sx={{ display: 'flex'}}>
                                                <Box>男</Box>
                                                <Box>女</Box>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={headColStyle}>
                                            <>合計</>
                                        </TableCell>
                                        <TableCell sx={headColStyle}>
                                            <>（内）<br />自転車</>
                                        </TableCell>
                                    </>
                                }
                                {
                                    outPersonItem &&
                                    <>
                                        <TableCell sx={headColStyle}>
                                            <>中高生</>
                                        </TableCell>
                                        <TableCell sx={headColStyle}>
                                            <>65歳以上</>
                                            <Box sx={{ display: 'flex'}}>
                                                <Box>男</Box>
                                                <Box>女</Box>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={headColStyle}>
                                            <>その他</>
                                            <Box sx={{ display: 'flex'}}>
                                                <Box>男</Box>
                                                <Box>女</Box>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={headColStyle}>
                                            <>合計</>
                                        </TableCell>
                                        <TableCell sx={headColStyle}>
                                            <>（内）<br />自転車</>
                                        </TableCell>
                                    </>
                                }
                                {/* {columns().map((row: any) => (
                                    <>
                                        {
                                            row.children.length < 1 &&
                                            <TableCell sx={row.style}>{row.label}</TableCell>
                                        }
                                        {
                                        row.children.length > 0 &&
                                            <TableCell>
                                                <>{row.label}</>
                                                <Box sx={{ display: 'flex'}}>
                                                    {row.children.map((d: any) => (
                                                        <>{d.label}</>
                                                    ))}
                                                </Box>
                                            </TableCell>
                                        }
                                    </>
                                ))} */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Datarows.map((row) => (
                                <TableRow>
                                    <TableCell sx={rowCrossStyle}>
                                        <>{row.count_date}</><br />
                                        <>{" " + row.count_time}</>                                            
                                    </TableCell>
                                    <TableCell sx={cellStyle}>
                                        <Box sx={{ display: 'flex' }}>
                                            <Box sx={{ width: '50px'}}>{row.frontTotal}</Box>
                                            <Box sx={{ width: '50px', borderLeft: '1px solid #000'}}>{row.front_traffic_jam_time}</Box>
                                            <Box sx={{ width: '50px', borderLeft: '1px solid #000'}}>{row.car_front_light}</Box>
                                            <Box sx={{ width: '50px', borderLeft: '1px solid #000'}}>{row.car_front_large}</Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell sx={cellStyle}>
                                        <Box sx={{ display: 'flex' }}>
                                            <Box>{row.car_side_a_total}</Box>
                                            <Box>{row.car_side_a_total}</Box>
                                            <Box>{row.car_side_a_light}</Box>
                                            <Box>{row.car_side_a_large}</Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell sx={cellStyle}>
                                        <Box sx={{ display: 'flex' }}>
                                            <Box>{row.car_side_b_total}</Box>
                                            <Box>{row.car_side_b_total}</Box>
                                            <Box>{row.car_side_b_large}</Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell sx={cellStyle}>
                                        <Box sx={{ display: 'flex' }}>
                                            <Box>{row.car_back_total}</Box>
                                            <Box>{row.car_back_light}</Box>
                                            <Box>{row.car_back_large}</Box>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {/* <div class="table_box">
                <table>
                <tr>
                    <th class="sticky_cross">絶対固定</th>
                    <th class="sticky_col">行固定</th>
                    <th class="sticky_col">行固定</th>
                    <th class="sticky_col">行固定</th>
                    <th class="sticky_col">行固定</th>
                    <th class="sticky_col">行固定</th>
                </tr>
                <tr>
                    <th class="sticky_row">列固定</th>
                    <td>テキスト</td>
                    <td>テキスト</td>
                    <td>テキスト</td>
                    <td>テキスト</td>
                    <td>テキスト</td>
                </tr>
                <tr>
                    <th class="sticky_row">列固定</th>
                    <td>テキスト</td>
                    <td>テキスト</td>
                    <td>テキスト</td>
                    <td>テキスト</td>
                    <td>テキスト</td>
                </tr>
                <tr>
                    <th class="sticky_row">列固定</th>
                    <td>テキスト</td>
                    <td>テキスト</td>
                    <td>テキスト</td>
                    <td>テキスト</td>
                    <td>テキスト</td>
                </tr>
                <tr>
                    <th class="sticky_row">列固定</th>
                    <td>テキスト</td>
                    <td>テキスト</td>
                    <td>テキスト</td>
                    <td>テキスト</td>
                    <td>テキスト</td>
                </tr>
                </table>
            </div> */}
        </Box>

    )
}


export default TrafficConfirmationScreen